exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("./../../../src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-boardgames-index-js": () => import("./../../../src/pages/boardgames/index.js" /* webpackChunkName: "component---src-pages-boardgames-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-music-index-js": () => import("./../../../src/pages/music/index.js" /* webpackChunkName: "component---src-pages-music-index-js" */),
  "component---src-pages-travel-index-js": () => import("./../../../src/pages/travel/index.js" /* webpackChunkName: "component---src-pages-travel-index-js" */)
}

